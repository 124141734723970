
import React from 'react';
import Colors from '../constants/ColorConstants';

const DecorateOneIcon = ({
    size = 24,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}) => (
    <svg width={size} height={1.17 * size} viewBox="0 0 76 89" enable-background="new 0 0 76 89" {...props}>
        <path xmlns="http://www.w3.org/2000/svg" id="パス_272" data-name="パス 272" d="M76.74,50.185c-.611-16.948-19.924-17.021-19.924-17.021C65.05,19.8,60.117,10.939,58.6,8.515,53.793.864,42.816-1.8,34.268,1.206,30.04,2.691,5.461,11.867,16.386,64.076A111.569,111.569,0,0,1,4.68,63.512L0,77.1a188.9,188.9,0,0,0,19.492.86c1.076,3.416,3.018,8.951,4.213,11.938H40.642c-1.8-4.2-4.15-9.526-5.1-12.611,37.831-1.829,41.429-20.58,41.195-27.106M31.258,64.435C27.742,49.174,26.984,40.3,28.569,30.868,30,22.34,34.709,11.893,43.809,14.283s6.086,18.664.637,25.008a.045.045,0,0,0,.041.073c1.439-.183,17.657-.7,19.13,8.11.583,3.48-.766,16.321-32.359,16.962" transform="translate(0 0)" fill="#737dff"/>
    </svg>
);

export default DecorateOneIcon;