import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const RDSButton = ({
  rectangle,
  color = "primary",
  "aria-label": ariaLabel,
  customClassNames,
  size = "md",
  innerRef,
  disabled,
  loading,
  children,
  className,
  onClick,
  block,
  href,
  as,
  ...props
}) => {
  function handleClick(e) {
    if (disabled) {
      e.preventDefault();
      return;
    }
    onClick && onClick(e);
  }

  const classes = classNames(
    className,
    "tw-inline-flex tw-items-center tw-justify-center tw-font-semibold tw-outline-none focus:tw-outline-none focus:tw-ring-0", {
      "tw-bg-brand-primary tw-int-white": color === "primary",
    }, {
      "tw-bg-brand-secondary tw-int-white": color === "secondary",
    }, {
      "tw-bg-light-green tw-int-white": color === "success",
    }, {
      "tw-bg-error-text tw-int-white": color === "danger",
    }, {
      "tw-border tw-border-brand-secondary tw-int-white": color === "white",
    }, {
      "tw-bg-[#12192c] tw-int-white": color === "black",
    }, {
      "tw-text-xs tw-px-4 tw-py-0.5": size === "sm",
      "tw-text-xs tw-px-4 tw-py-2": size === "smd",
      "tw-text-base tw-px-12 tw-py-2": size === "md",
      "tw-text-base tw-px-[100px] tw-py-3.5": size === "lg",
    }, {
      "tw-cursor-not-allowed tw-opacity-35": disabled,
      "hover:tw-opacity-80": !disabled,
    }, {
      "tw-cursor-wait": loading,
    }, {
      "!tw-block tw-w-full": block,
    }, {
      "tw-rounded-full": !rectangle,
    },
    customClassNames,
  );

  const loadingClasses = classNames(
    "tw-animate-spin tw-stroke-current tw-ml-4", {
      "tw-h-3 tw-w-3": size === "sm",
      "tw-h-4 tw-w-4": size === "md",
      "tw-h-6 tw-w-6": size === "lg",
    }
  );

  const Tag = as || (href ? 'a' : 'button');

  return (
    <Tag
      {...props}
      {...props.dataSet}
      href={href}
      className={classes}
      ref={innerRef}
      onClick={handleClick}
      aria-label={ariaLabel}
      disabled={disabled || loading}
    >
      {children}
      {loading && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={loadingClasses}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
      )}
    </Tag>
  );
};

RDSButton.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "success", "danger", "white"]),
  "aria-label": PropTypes.string,
  disabled: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["sm", "smd", "md", "lg"]),
  children: PropTypes.node,
  qaSelector: PropTypes.string,
  customClassNames: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default RDSButton;
