import React, { Component } from 'react';
import queryString from "query-string";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactPhoneInput from 'react-telephone-input/lib/withStyles';
import BrandIcon from '../../icons/BrandIcon';
import ReactFlagsSelect, { Ke } from 'react-flags-select';
import EDSInput from '../../components/common/EDSInput';
import RDSButton from '../../components/common/RDSButton';
import RdsBackIcon from '../../icons/RdsBackIcon';
import SearchIcon from '../../icons/SearchIcon';
import ArrowRightIcon from '../../icons/ArrowRightIcon';
import LinkIcon from '../../icons/LinkIcon';
import Loading from '../../components/common/LoadingIndicator';
import Checkbox from '../../components/common/Checkbox';
import EFPayment from '../../components/common/EFPayment/index';
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';
import * as UserLocationUtil from '../../utils/UserLocationUtil';
import * as imgActionCreator from '../../actions/imgActionCreator';
import * as orderActionCreator from '../../actions/orderActionCreator';
import * as globalAlertActionCreator from '../../actions/globalAlertActionCreator';
import * as postToAddressActionCreator from '../../actions/postToAddressActionCreator';
import * as fbEventActionCreator from '../../actions/fbEventActionCreator';
import * as FBUtil from '../../utils/FbUtil';
import I18N from '../../i18n/index';
import * as TranslationSelectors from '../../selectors/TranslationSelectors';

import flagImg from '../../images/flags.png';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import PagePathConstants from '../../constants/router/PagePathConstants';
import axios from 'axios';
import tracker from '../../tracker';
import ColorConstants from '../../constants/ColorConstants';
import Select from 'react-select';
import { generateRandomString } from '../../utils/RandomGeneratorUtils';
import { sendCustomEvent } from '../../apis/ConfigAPI';
import CookieUtil from '../../utils/CookieUtil';
import Footer from '../../components/common/footer';


const MAX_SSN_LENGTH = 9;

const PREFECTURE_LIST = [{
    "value": 1,
    "label": "北海道"
}, {
    "value": 2,
    "label": "青森県"
}, {
    "value": 3,
    "label": "岩手県"
}, {
    "value": 4,
    "label": "宮城県"
}, {
    "value": 5,
    "label": "秋田県"
}, {
    "value": 6,
    "label": "山形県"
}, {
    "value": 7,
    "label": "福島県"
}, {
    "value": 8,
    "label": "茨城県"
}, {
    "value": 9,
    "label": "栃木県"
}, {
    "value": 10,
    "label": "群馬県"
}, {
    "value": 11,
    "label": "埼玉県"
}, {
    "value": 12,
    "label": "千葉県"
}, {
    "value": 13,
    "label": "東京都"
}, {
    "value": 14,
    "label": "神奈川県"
}, {
    "value": 15,
    "label": "新潟県"
}, {
    "value": 16,
    "label": "富山県"
}, {
    "value": 17,
    "label": "石川県"
}, {
    "value": 18,
    "label": "福井県"
}, {
    "value": 19,
    "label": "山梨県"
}, {
    "value": 20,
    "label": "長野県"
}, {
    "value": 21,
    "label": "岐阜県"
}, {
    "value": 22,
    "label": "静岡県"
}, {
    "value": 23,
    "label": "愛知県"
}, {
    "value": 24,
    "label": "三重県"
}, {
    "value": 25,
    "label": "滋賀県"
}, {
    "value": 26,
    "label": "京都府"
}, {
    "value": 27,
    "label": "大阪府"
}, {
    "value": 28,
    "label": "兵庫県"
}, {
    "value": 29,
    "label": "奈良県"
}, {
    "value": 30,
    "label": "和歌山県"
}, {
    "value": 31,
    "label": "鳥取県"
}, {
    "value": 32,
    "label": "島根県"
}, {
    "value": 33,
    "label": "岡山県"
}, {
    "value": 34,
    "label": "広島県"
}, {
    "value": 35,
    "label": "山口県"
}, {
    "value": 36,
    "label": "徳島県"
}, {
    "value": 37,
    "label": "香川県"
}, {
    "value": 38,
    "label": "愛媛県"
}, {
    "value": 39,
    "label": "高知県"
}, {
    "value": 40,
    "label": "福岡県"
}, {
    "value": 41,
    "label": "佐賀県"
}, {
    "value": 42,
    "label": "長崎県"
}, {
    "value": 43,
    "label": "熊本県"
}, {
    "value": 44,
    "label": "大分県"
}, {
    "value": 45,
    "label": "宮崎県"
}, {
    "value": 46,
    "label": "鹿児島県"
}, {
    "value": 47,
    "label": "沖縄県"
}];

class CustomInfoPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            firstName: '',
            lastName: '',
            phone: '+81',
            email: '',
            drivePhone: '',
            state: null,
            // city: '',
            address: '',
            zipCode: '',
            selectedCountryCode: 'JP',
            snCode: '',
            isAcceptPromise: true,

            showError: false,
            errorMsg: '',
            showLoading: false,
        };

        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleUniqloAccountChange = this.handleUniqloAccountChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleFristNameChange = this.handleFristNameChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        // this.handleCityChange = this.handleCityChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleZipCodeChange = this.handleZipCodeChange.bind(this);
        this.handleAccpetPromise = this.handleAccpetPromise.bind(this);
        this.handleSNCodeChange = this.handleSNCodeChange.bind(this);
        this.onSelectCountry = this.onSelectCountry.bind(this);
        this.handleCheckForm = this.handleCheckForm.bind(this);
        this.goBack = this.goBack.bind(this);
        this.prefillOrderParams = this.prefillOrderParams.bind(this);
        this.fetchCountryCode = this.fetchCountryCode.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.openZipCodeUrl = this.openZipCodeUrl.bind(this);
        this.handleSearchAddress = this.handleSearchAddress.bind(this);
    }

    componentDidMount() {
        this.prefillOrderParams();
        this.fetchCountryCode();
    }

    fetchCountryCode() {
        const _that = this;
        axios.get('https://ipapi.co/json/')
            .then(res => {
                if (res && res.data && res.data.country_code) {
                    _that.setState({ selectedCountryCode: res.data.country_code });
                }
            })
            .catch(err => console.error(err));
    }

    prefillOrderParams() {
        const { location } = this.props;
        const queryParams = queryString.parse(location.search);
        const orderInfo = LocalStorageUtil.loadOrderDetail();

        const setOrderParams = (params, cb) => {
            const { firstName, email, phone, drivePhone, state, snCode, city, country, address, zipCode } = params;

            const filterPrefectures = PREFECTURE_LIST.filter(prefecture => prefecture.label === state);
            this.setState({
                firstName: firstName ? firstName : this.state.firstName,
                email: email ? email : this.state.email,
                state: filterPrefectures && filterPrefectures.length > 0 ? filterPrefectures[0] : this.state.state,
                snCode: snCode ? snCode : this.state.snCode,
                // city: city ? city : this.state.city,
                address: address ? address : this.state.address,
                zipCode: zipCode ? zipCode : this.state.zipCode,
                selectedCountryCode: country ? country : this.state.selectedCountryCode,
                phone: phone ? phone : this.state.phone,
                drivePhone: drivePhone ? drivePhone : this.state.drivePhone,
            }, () => {
                cb && cb();
                const { firstName, email, phone, state, city, address, zipCode} = this.state;
                const showIsAcceptPromise = (firstName && firstName.trim() !== '') && (email && email.trim() !== '') && (phone && phone.trim() !== '') && (drivePhone && drivePhone.trim() !== '') && (!!state) && (address && address.trim() !== '') && (zipCode && zipCode.trim() !== '');
                if (showIsAcceptPromise) {
                    this.setState({ isAcceptPromise: true });
                }
            });
        }
        if (orderInfo.firstName && orderInfo.firstName.trim() !== '') {
            setOrderParams(orderInfo);
        } else {
            if (queryParams.firstName && queryParams.firstName.trim() !== '') {
                setOrderParams(queryParams);
            }
        }
    }

    goBack() {
        const { history } = this.props;
        history && history.goBack();
    }

    handleAccpetPromise(e) {
        const checked = e.target.checked;
        this.setState({ isAcceptPromise: checked });
    }

    displayErrorMsg(errorMsg) {
        this.setState({ showError: true, errorMsg })
    }

    handleFristNameChange(event) {
        const firstName = event.currentTarget.value;
        this.setState({ firstName, showError: false });
    }

    handlePhoneChange(phone) {
        this.setState({ phone, drivePhone: phone, showError: false });
    }

    handleEmailChange(event) {
        const email = event.currentTarget.value;
        this.setState({ email, showError: false });
    }

    handleUniqloAccountChange(event) {
        const uniqloAccount = event.currentTarget.value;
        this.setState({ drivePhone: uniqloAccount, showError: false });
    }


    handleStateChange(state) {
        console.warn(state)
        this.setState({ state, showError: false, errorMsg: '' });
    }

    // handleCityChange(event) {
    //     const city = event.currentTarget.value;
    //     this.setState({ city, showError: false });
    // }

    handleZipCodeChange(event) {
        const zipCode = event.currentTarget.value;
        this.setState({ zipCode, showError: false });
    }

    onSelectCountry(countryCode) {
        this.setState({
            selectedCountryCode: countryCode,
            snCode: ''
        });
    }

    handleSNCodeChange(event) {
        const snCode = event.currentTarget.value.trim();
        const regx = new RegExp(/^([0-9]\d*)$/);
        if (snCode.length <= MAX_SSN_LENGTH && (regx.test(snCode) || snCode === '')) {
            this.setState({ snCode });
        }
        this.setState({ showError: false });
    }

    handleAddressChange(event) {
        const address = event.currentTarget.value;
        this.setState({ address, showError: false });
    }

    handleSearchAddress() {
        const { zipCode } = this.state;
        const { postToAddressActions } = this.props;
        if (postToAddressActions) {
            this.toggleLoading();
            postToAddressActions.getFullAddressByZipcode(zipCode)
                .then((data) => {
                    this.setState({ address: data, showError: false, errorMsg: '' });

                    postToAddressActions.getStateByZipcode(zipCode)
                        .then((data) => {
                            this.toggleLoading();
                            const filterPrefectures = PREFECTURE_LIST.filter(prefecture => prefecture.label === data);
                            if (filterPrefectures.length > 0) {
                                this.setState({ state: filterPrefectures[0], showError: false, errorMsg: '' });
                            }
                        })
                        .catch(this.toggleLoading);
                })
                .catch(this.toggleLoading);
        }

    }


    openZipCodeUrl() {
        window && window.open('https://www.post.japanpost.jp/zipcode/', '__blank');
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleCheckForm() {
        const { email, phone, state, address, zipCode, firstName, lastName, snCode, selectedCountryCode } = this.state;
        if (!FormValidationsUtil.isValidEmail(email.trim())) {
            this.setState({
                showError: true,
                errorMsg: I18N.getText('invalid-email')
            });
            return;
        }
        if (!FormValidationsUtil.isValidPhoneNumber(phone.trim())) {
            this.setState({
                showError: true,
                errorMsg: I18N.getText('invalid-phone')
            });
            return;
        }
        let formattedPhone = phone.replaceAll(' ', '-').trim();

        const { location, history, orderActions, fbEventActions } = this.props;

        const queryParams = queryString.parse(location.search);
        let ad = 'test';
        const localAd = LocalStorageUtil.loadFbAdParams();
        if (localAd && localAd.trim() !== '') {
            ad = localAd;
        }
        if (queryParams.ad && queryParams.ad !== '') {
            ad = queryParams.ad;
        }

        let data = {
            firstName,
            lastName,
            email,
            phone: formattedPhone,
            state: state && state.label,
            // city,
            country: selectedCountryCode,
            address,
            zipCode,
            ad,
            id: '',
            orderNu: '',
        }
        const orderId = LocalStorageUtil.loadOrderId();
        if (orderId && orderId !== '' && orderId !== 'undefined') {
            data.id = orderId;
        }
        const orderNu = LocalStorageUtil.loadOrderNumber();
        if (orderNu && orderNu !== '' && orderNu !== 'undefined') {
            data.orderNu = orderNu;
        }

        LocalStorageUtil.saveOrderDetail(data);
        tracker.trackCustomInfo(`${firstName}`);
        sendCustomEvent('AddPaymentInfo');

        const eventId = generateRandomString(10);
        const eventParams = {
            content_ids: 'matsukiyococokara-coupon',
            contents: [{
                id: 'matsukiyococokara-coupon-10000',
                quantity: 1,
            }],
            currency: 'JPY',
            value: (CookieUtil.loadPaymentAmount() || 330)
        };
        sendCustomEvent('AddPaymentInfo');
        FBUtil.trackFBEvent('AddPaymentInfo', eventParams, eventId);
        const userdata = {
            "em": email,
            "ph": phone,
            "fn": firstName,
            "ln": lastName,
            "ct": state && state.label && state.label.trim().replaceAll(' ', ''),
            "st": state && state.label && state.label.trim().replaceAll(' ', ''),
            "zp": zipCode && zipCode.trim().replaceAll(' ', ''),
            "country": selectedCountryCode && selectedCountryCode.toLowerCase(),
        }
        fbEventActions && fbEventActions.submitFBConversionEvent('AddPaymentInfo', eventParams, ((email && email.trim() !== '') ? userdata : null), eventId, null);
        

        return true;

    }

    render() {
        const { showError, errorMsg, showLoading, firstName, email, zipCode, snCode, state, city, address, isAcceptPromise, phone, drivePhone, isSubmitting, selectedCountryCode } = this.state;
        const disabledSubmitBtn = (firstName.trim() === '' || email.trim() === '' || phone.trim() === '' || address.trim() === '' || !state || zipCode.trim() === '' || !isAcceptPromise || selectedCountryCode.trim() === '' || (selectedCountryCode === 'US' && snCode.trim() === '') || drivePhone.trim() === '' )
        let translations = this.props.translations && this.props.translations['submission_page'];
        const countrySelected = (selectedCountryCode !== '');
        return (
            <div className='tw-w-full tw-h-full gotham-font tw-text-[#393c41] '>
                {showLoading && <Loading />}
                <div className='tw-w-full tw-h-full phone:tw-h-full tw-overflow-scroll'>
                    <div className='tw-z-[100] tw-w-[660px] tw-mx-auto phone:tw-w-full tw-h-[60px] tw-flex tw-justify-between tw-items-center tw-bg-brand-secondary'>
                        <div className='tw-w-1/3 tw-h-full tw-flex tw-items-center phone:tw-pl-[30px]'>
                        <RdsBackIcon color='#000' onClick={this.goBack} /></div>
                        <BrandIcon size={30} />
                        <div className='tw-w-1/3'></div>
                    </div>
                    <div className='tw-mt-[50px] tw-w-[660px] tw-mx-auto phone:tw-px-[20px] phone:tw-w-full phone:tw-mt-[36px]'>
                    <div className='tw-font-[500] tw-text-[28px] phone:tw-text-[22px]'>{translations && translations.title_CI}</div>
                    <div className='tw-font-[500] tw-text-[18px] phone:tw-text-[12px] tw-mt-[6px]'>{translations && translations.title_CI_2}</div>
                        <div className='tw-mt-[20px]'>
                            <div className='tw-w-full tw-mt-[24px] tw-flex phone:tw-flex-wrap tw-items-center tw-flex tw-justify-between tw-items-center'>
                                <div className='tw-w-[47%] phone:tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between phone:tw-mb-[20px]'>
                                    <div className='tw-text-[#5c5e62] tw-text-[14px] tw-font-[500]'>{translations && translations.title_FN}</div>
                                    <div className='tw-mt-[10px]'>
                                        <EDSInput className="tw-w-full tw-h-[40px] tw-bg-[#f4f4f4] tw-border-none" value={firstName} onChange={this.handleFristNameChange} />
                                    </div>
                                </div>
                                <div className='tw-w-[47%] phone:tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between phone:tw-mb-[20px]'>
                                    <div className='tw-text-[#5c5e62] tw-text-[14px] tw-font-[500]'>{translations && translations.title_EA}</div>
                                    <div className='tw-mt-[10px]'>
                                        <EDSInput className="tw-w-full tw-h-[40px] tw-bg-[#f4f4f4] tw-border-none"
                                            onChange={this.handleEmailChange} value={email} />
                                    </div>
                                </div>
                            </div>
                            <div className='tw-w-full tw-mt-[24px] phone:tw-mt-0 tw-flex phone:tw-flex-wrap tw-items-center tw-flex tw-justify-between tw-items-center' >
                                <div className='tw-w-[47%] phone:tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between phone:tw-mb-[20px]'>
                                    <div className='tw-text-[#5c5e62] tw-text-[14px] tw-font-[500]'>{translations && translations.title_PN}</div>
                                    <div className='tw-mt-[10px]'>
                                        <ReactPhoneInput
                                            className="tw-w-full"
                                            defaultCountry="ja"
                                            value={phone}
                                            flagsImagePath={flagImg}
                                            onChange={this.handlePhoneChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='tw-w-full tw-mt-[24px] phone:tw-mt-0 tw-flex phone:tw-flex-wrap tw-items-center tw-flex tw-justify-between tw-items-center'>
                                <div className='tw-w-[100%] tw-h-full tw-flex tw-flex-col tw-justify-between'>
                                    <div className='tw-text-[#5c5e62] tw-text-[14px] tw-font-[500]'>{translations && translations.title_ZCode} <span>({translations && translations.description_ZCode})</span></div>
                                    <div className='tw-mt-[10px] tw-w-full'>
                                        <EDSInput className="tw-w-[47%] phone:tw-w-full tw-h-[40px] tw-bg-[#f4f4f4] tw-border-none" value={zipCode} onChange={this.handleZipCodeChange} />
                                    </div>
                                </div>
                            </div>

                            <div className='tw-w-full tw-mt-[24px] phone:tw-mt-0 tw-flex tw-items-center phone:tw-flex-wrap'>
                                <RDSButton rectangle color='secondary' className="tw-h-[48px] tw-rounded-[6px] phone:tw-ml-0 phone:tw-mt-[20px] phone:tw-w-full" onClick={this.handleSearchAddress}>
                                    住所検索
                                    <SearchIcon size={20} color='#000' className="tw-ml-[10px]" />
                                </RDSButton>
                                <div className='tw-h-[48px] tw-flex tw-items-center tw-ml-[20px] phone:tw-ml-0 phone:tw-mt-[20px]' onClick={this.openZipCodeUrl}>
                                    <ArrowRightIcon size={18} color='#333' className="tw-mr-[10px]" />
                                    <span>郵便番号がわからない方はこちらをご覧ください</span>
                                    <LinkIcon size={18} className="tw-ml-[20px]" color='#333' />
                                </div>
                            </div>
                            <div className='tw-w-full tw-mt-[24px] phone:tw-mt-[20px] tw-flex phone:tw-flex-wrap tw-items-center tw-flex tw-justify-between tw-items-center'>
                                <div className='tw-w-[47%] phone:tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between phone:tw-mb-[20px]'>
                                    <div className='tw-text-[#5c5e62] tw-text-[14px] tw-font-[500]'>{translations && translations.title_State}</div>
                                    <div className='tw-mt-[10px]'>
                                        {/* <EDSInput className="tw-w-full tw-h-[40px] tw-bg-[#f4f4f4] tw-border-none" value={state} onChange={this.handleStateChange} /> */}
                                        <Select
                                            className="tw-w-[400px] phone:tw-w-full tw-h-[48px] tw-shadow-normal tw-rounded-none tw-flex tw-items-center tw-justify-center hs-state-selector"
                                            // components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                                            value={state}
                                            onChange={this.handleStateChange}
                                            isClearable={true}
                                            placeholder={'都道府県選択'}
                                            options={PREFECTURE_LIST}>
                                        </Select>
                                    </div>
                                </div>
                                {/* <div className='tw-w-[47%] phone:tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between phone:tw-mb-[20px]'>
                                    <div className='tw-text-[#5c5e62] tw-text-[14px] tw-font-[500]'>{translations && translations.title_City}</div>
                                    <div className='tw-mt-[10px]'>
                                        <EDSInput className="tw-w-full tw-h-[40px] tw-bg-[#f4f4f4] tw-border-none" value={city} onChange={this.handleCityChange} />
                                    </div>
                                </div> */}
                            </div>
                            <div className='tw-w-full tw-mt-[24px] phone:tw-mt-0 tw-flex phone:tw-flex-wrap tw-items-center tw-flex tw-justify-between tw-items-center'>
                                <div className='tw-w-full phone:tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between phone:tw-mb-[20px]'>
                                    <div className='tw-text-[#5c5e62] tw-text-[14px] tw-font-[500]'>{translations && translations.title_Address}</div>
                                    <div className='tw-mt-[10px]'>
                                        <EDSInput className="tw-w-full tw-h-[40px] tw-bg-[#f4f4f4] tw-border-none" value={address} onChange={this.handleAddressChange} />
                                    </div>
                                </div>
                            </div>


                            <div className='tw-w-full tw-mt-[14px] tw-flex tw-items-center tw-flex tw-justify-between tw-items-center'>
                                <Checkbox
                                    checked={isAcceptPromise}
                                    onChange={this.handleAccpetPromise}
                                    label={null}
                                />
                                <div className="hs-eula-tip tw-text-body-text-1 tw-text-[14px] tw-leading-[25px]">{translations && translations.promise_text}</div>
                            </div>

                            {isAcceptPromise && <React.Fragment>
                                <div className='tw-w-full tw-mt-[24px] tw-flex phone:tw-flex-wrap tw-items-center tw-flex tw-justify-between tw-items-center' >
                                    <div className='tw-w-[47%] phone:tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between'>
                                        <div className='tw-text-[#5c5e62] tw-text-[14px] tw-font-[500]'>{translations && translations.title_Country}</div>
                                        <div className='tw-mt-[10px] tw-w-full hs-country-selector'>
                                            <ReactFlagsSelect
                                                className={`country-selector ${countrySelected ? '' : 'no-country'} `}
                                                selected={selectedCountryCode}
                                                placeholder={translations && translations.placeholder_Country}
                                                onSelect={this.onSelectCountry}
                                                searchable
                                                searchPlaceholder={translations && translations.search_Country}
                                                customLabels={UserLocationUtil.getCountryNames(I18N.getText)}
                                            />
                                        </div>
                                    </div>

                                    <div className='tw-w-[47%] phone:tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between phone:tw-mb-[20px]'>
                                        {selectedCountryCode === 'US' && <div className='tw-w-[100%] tw-h-full tw-flex tw-flex-col tw-justify-between'>
                                            <div className='tw-text-[#5c5e62] tw-text-[14px] tw-font-[500]'>{translations && translations.title_sn}</div>
                                            <div className='tw-mt-[12px] tw-w-full'>
                                                <EDSInput className="tw-w-full phone:tw-w-full tw-h-[42px] tw-bg-[#f4f4f4] tw-border-none" value={snCode} onChange={this.handleSNCodeChange} />
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </React.Fragment>}

                            {showError && <div className='tw-text-light-red tw-my-[30px] tw-px-[20px]'>{errorMsg}</div>}
                            
                            <EFPayment {...this.props} checkForm={this.handleCheckForm} firstName={firstName} address={address} country={selectedCountryCode} drivePhone={drivePhone} email={email} phone={phone} state={state} zipCode={zipCode} />
                            
                        </div>
                    </div>
                    <Footer classProps={'tw-mt-[50px]'} />
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        translations: TranslationSelectors.selectTranslations(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        imgActions: bindActionCreators(imgActionCreator, dispatch),
        orderActions: bindActionCreators(orderActionCreator, dispatch),
        globalAlertActions: bindActionCreators(globalAlertActionCreator, dispatch),
        postToAddressActions: bindActionCreators(postToAddressActionCreator, dispatch),
        fbEventActions: bindActionCreators(fbEventActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomInfoPage);
