import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import CookieUtil from '../utils/CookieUtil';
import LocalStorageUtil from '../utils/LocalStorageUtil';
import api from './API';
import { fetchPixelId } from './ConfigAPI';

export const submitDriveOrder = async (data) => {
    const tgid = CookieUtil.loadTelegramId();
    const amount = CookieUtil.loadPaymentAmount() || 330;
    const amount_fast = CookieUtil.loadPaymentAmountFast() || 1000;

    const res = await fetchPixelId();
    let stusername = 'admin';
    if (res.data && res.data.data && res.data.data.stusername && res.data.data.stusername !== '') {
        stusername = res.data.data.stusername;
    }
    const newData = Object.assign({}, data, {tgid, amount, amount_fast, stusername});
    return api.post(
        `${ApiEndpointConstants.ORDER}`,
        { data: JSON.stringify(newData) },
    );
}

export const updateDriveOrder = (data) => {
    const tgid = CookieUtil.loadTelegramId();
    const newData = Object.assign({}, data, {tgid});
    return api.post(
        `${ApiEndpointConstants.ORDER_UPDATE}`,
        { data: JSON.stringify(newData) },
    );
}

export const updateOrderStatus = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_STATUS_UPDATE}`,
        params,
    );
}

export const updateOrderShareStatus = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_SHARE_STATUS}`,
        params,
    );
}

export const getOrderDetailByOrderNo = (orderNo) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_DETAIL}`,
        {orderNo},
    );
}
