
import React from 'react';
import Colors from '../constants/ColorConstants';

const BrandIcon = ({
    size = 24,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}) => (
    <svg width={4.6 * size} height={size} viewBox="0 0 175 38" enable-background="new 0 0 175 38" {...props}>
        <image id="image0" width="175" height="38" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK8AAAAmCAYAAACyLctlAAAAIGNIUk0AAHomAACAhAAA+gAAAIDo
AAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAD/AP8A/6C9p5MAACIsSURBVHja7Xx7dFTV2fdv
7zPnnMyQTAKEBAghElBuUkAukiBYS7gEKfEGtqJ1vYj3S+1blsXq66Wtbe2rVLus/VS0BUvFFKQf
VBCUiwEMvMWARBIMN4MEAgESSCYz57L38/0x2YeZyQ2q77KX71lrr2RmztmXZz/72b/nsjcjInRE
jY2NHf6+fv16+Hw+RCIRaJoGxhhc14WmaZBS4uzZs+CcY+7cuUlE1JUx1osxlg9gppRyHAAwxlYB
eAxAdWL9nPMO2++s/x9++CGEEAAAKSUKCgpY4jPNzc1obGykUCiElv5A13VYloUBAwbgX5k649+K
FStgmiYKCgqYYRiMJIFxBsaiBQC1FK+u2Do3bNgATdO8z5zzuDndu3dvh+0PHz487nOsrPm+CgY4
jgNN00BEYIyBJEFA4Oabb04FcCURXU1EozjnuVLKXoyxLgAYY0wN9LsAtJa/XykpJl911VXddV3/
DwCXA2CxTA4EAhQIBM4C+JNwxf9UH6kmIoJhGF91d/7p6Prrr/cLIW7Udf1qIvIzjbEWoVXUDOAD
AO8wxpzE9xljEELA5/MhPz9f13U9ThtdddVVbbab0Ib3mYjE9u3bXSnllxdeKSVM08TMmTN9ALoA
6AYg37Ks6wB8C0BXxs4PWAgBzjkcx0FzczOCwSAAcAATAKQBaPgqmW9ZFiZPntyTiFYDGIUWwU1k
EBFBCjlL82nXp6Sk7IhEIq0Y+O9InPMnOOfzAfg64MdcAM8A+BkAO/YHv9+P/Pz8noyxxwEUAQi0
VUF7O0AbQhzOy8tbDeAn7EvChuSUlJRhwhXjNZ82GsBlRNSPMRZEVCBhWRZM04TjOJBSorq6GmvX
rkVFRQW6dOmC559/XtV1BMAwAOcSmNdh/zrrPxExxtgPAfwKMYLb3uMANgD49uHDhyMAkJubi39l
6oR/OZZlbQeQaZpmZ7z7nIi+zRj7VNXZInhdiegNAEXsIrUBEUWLPN9Hx3WQlJQkAbzXqeYVQkDT
NOi6rmma1kXX9VQAYwHcAGAygO6aT+OqMdU/x3Hgui7q6+tRVVWFt956C++++y6OHTsGIkLv3r2x
YcMGr58APkKC4H4VxBjTpZSTSBJzhYvly5dj7969aG5uhmmakFJi8ODBuP17t0PzaQzAIABBznnE
cZwv2/w/O91iGEYmALZjxw4sX74cgUAAzc3NMAwDQgg8/PDDyMzMBGOsKxFlxL5MRLBte4RhGN9k
jLFwOIxIJHJRHeCcw3Vd+Hw+WJaFtLQ0AOBSysmdCW9SMBi8DMAYzvkoAJcT0SAi6sYY44wxRkRw
XRe6roOIsH//fpSXl2PLli0oLy/HgQMHcOLECViW5eFiTdMwf/58DBo0SK38OgAv/S9NQG/XdYcb
hoG6U3V47LHHcOLECUQiEei6DsdxMH/+fAgpoEEjAPvC4fDZGIPk35XSANzGGGOO42DJkiV4/fXX
lUACAIYOHYoFCxao5xsAnEisxDTNmQCCQNT4e/XVVy+qE1JK+Hw+uK6LtLQ0vPDCC8jJyYGu6/y8
aiZiRGQQUSoRfYuIfktE1UQkKYaEEF5xHZeam5uprKyMHn30URo8eDBpmkaMMWWBev8zxkjXdQJA
o0aNopMnTxIRSSmlLaV8QEqJtkpnFNP/9spDtm0LKSWtWLHC6xcA0nWdDMOgzZs3ExGRZVkOEd1e
W1uL6upqHDp06OsWoP916oBvE4lIEBHZtk2DBw/2eAaANE2jp59+mlzHVaKxmohSEuYvnYg+JyI6
e/YsTZs2jRhjF12UTE2dOpWOHz+u2qvxATAAfLOlfAPAcAA9gXhjTrgCrnBRW1uLffv2obS0FPv2
7UNZWRkOHjx43tPQ8pcxBk3TPLUvpYTjOOjbty9+85vfoEePHrBtG7qurwGwKJGhnPNO8ewFTEyQ
MXabz+fjruuipKQEALx+Oo6DAQMGYODAgQqbH5dSljQ3N4NzHufi+XegGNjHAVzd8hcbN25EdXXU
i+m6LgzDQDAYxIwZM6D5NCCqDN4H0BTjQUJLHVkAcPLkSXz66acXPaecc8/VmZ+frwx8ArDeJ6W8
n3P+S8uyDMMwvIlVQFmSxJEjR/DnP/8Zy5YtwyeffNJmA7E+PvW/0pyqTtM0cd999yE/Px+O40DX
dUlEYZL0ZAsTACBMRB8A2L5161a5ePFipKWl4dvf/nYaEc3inPdDa8NLANgTDodXb968ORwOh6Fp
GmbOnHmFlHIk5xzhcBgffvghOOcwDMPDXt/97nfRrVs35Rb7hHN+vF+/fhoRXUNERUKILiTp78YP
jDNwziGl7NT4vFBSRvBXQZFIBElJSd7cxQjvNy3LghACb775JhzHgWmasCwLtm2jf//+6NevXyz/
JwEYqebbtm0YhjESLUpw79690HUdvXv3vqj+CSFgWRaCwSCuvfZa+P1+EFEEwEq4jnsqRvXTkSNH
aMOGDfTss8/SvHnzqH///pScnBy33bZXkpOTPVV/6aWXUmFhIZmmSbquE+ecJk6cSLW1tWRZFrmO
S5ZlUQt2INu2SW1RUkpHSvkyERkrV66ElHIkEX2itrFEsm2bbNt2pZQbiajPmjVrIITQiOhZBXFW
r15Nfr8/DspkZmbS7t27FRySRPR927ZNIlpo23aEviISQnjj/LKkeOY6LrmOS5FIJK7+v6cN9Z6U
0oOE4XCYXMelAwcOUEZGBnHO4+Dgj370o7j3Youay9ixJ8zVBRfLskgIQaFQSMkGSSmrpZT9oQTi
s88+o1mzZlEwGCS/30+apsUJpqZprfCsKpxz73+/308PP/wwHThwgObOnev9npqaSh999BFZlhUn
qGoi2mDmOddxxwghkojoL5FIpNNZkdGZ+zMR/Ydt23cT0V414bfccgtxzuP6mp+fT6FQSAmCQ0RP
E9HjRBSSX4WkJYwpUVD+nhIrwG3Vf7F9ch03zo5RRf3+2muvxckCY4w457RhwwZPCcUW1Q9Vb3v9
vdh+CiHIsizFh3opZb6vZYvAuXPn8N5773l+3VhLW237bRlQChtedtllmDZtGubNm4dLL70US5Ys
wVtvvQUgGtK7//77kZubi0WLFuHs2bMwDAPDhw9H//79sWLFCkgpQUTIy8vDxIkTIaXsovm0UY7j
WIyxsaZpMsdx8NJLrZ0SeXl5uOKKK2AYBgNwE4CbdF1XWxcOHTqEVatWeSFf1e+CggKYhqlwmw/A
E7Fj3rJlCz744IMvtS0LIeD3+3HXXXfBNE0sXLjwS9Wn6zpc18WsWbMwZMgQLFmyBJ9//jlCoRCC
wSBUiPtCSYXCx40bh2nTpuGjjz7CmjVroGkaXNfF+vXrPdhDLdCPc441a9Z4MEwI4cEi27ahaRq6
du2KefPmIRQKYcmSJYhEIp4tc7HkOA4Mw8CcOXOQm5sLznkSEfWEku6//e1vlJKS0srSQ4vmTNS4
pmkS55wCgQAtWLCAjh49SkTRbaK8vJyGDh3qPTts2DCqrq6myspKysnJ8b6fNWsWrV+/Pm5FP/nk
k2orlET0vJTyTiKKSCmpvLy8Vf9SUlJo5cqVrbSJWv1ERG+88QYZhhE3Bk3TaP369W1qHFXPbbfd
1ilU0jSNkpKSPKu4rWcKCwupsbGR3n777QuCX4q/7f3Wp08fOn78OFVVVVFWVhYlJSV5fWnr+cS5
S7TkOee0cOFCklLSnDlzOq1DtRPrQUKM90bTNCooKKAzZ87Q4sWLye/3k2EYnix1VFS9qg1d10nX
deratSsdOXJEzekZIUReh35eSjC8NE3zwrvZ2dmYMWMG7r33XuTk5MA0TRARIpEIFi5ciP379wMA
kpOT8dOf/hR9+/ZFTU0N0tLSPMv19OnTCAaDSElJQXNzMzRNw+nTp5VGYAAGMMZyABiMMfz+979v
1ccuXbpg9OjR2LdvH3bv2g0ho1rAMAzYtg3XdfHOO+/Atm0wdt54Sk5Oxr59+1BXVxdXn8/nw/jx
4yGEwPr16zvVCkrrKK2iLGNFuq7j1ltvhWEYWLp06QVpQtM0IYTwDKREGjp0KHr27ImSkhKcPXvW
Mz4T21b1qb6pZKnYHVQIgYyMDOTn56Ourg6lpaWt3o2VBcaYlxLgOA4cx4nz3qhnZs6cCZ/Ph7fe
egvhcNirsyP3p/JQaZqmPFFenTNmzEBmZia4xiFccUjzaQc61LxtrcBgMEhPPfUUVVdXx+GSSCRC
ruPSkiVLvNVoGAY99thj3jN1dXU0fvx4b0UNGDCAysrKKD093av/pptu8kC667hHiKhOSilra2tp
1KhRrTTvDdffQM3NzXTHHXd4hqFqO3bnUJhd/aY0iFrZqqSnp9PWrVvpd7/7Xbs8wEVoSqUlKysr
qUePHq3aSyyxWq29tn79619Tc3Mz3X///d53SvsmFs55HF+QoHkB0MiRI6m2tpZWrVpFqampnY61
rb4xxrzduHv37rR//34qLy/36mOMeVq5o6LqVvPHGKOkpCRasWKFJxdE9KJlWZqPYkK6ba0EpU2G
DRuGSZMmYf78+eiZ2VPhRAhXQPNpME0TlZWVeOqpp7zVMmzYMDz00ENxWqhbt27e5/r6enDOkZqa
ilOnToFzjtOnT3t+YgJlC1dAkkR5eTkqKyvjNAAAXH/D9aipqcG6deu897z3Y54VQoAx5mWKKa2W
qK0GDhyI4cOHIzk5GX/5y186zSyzLAuBQAANDQ148MEHceJEfJBp4sSJyMjIgK7rWLlyZZuaNJZs
24bP5wMRYenSpViyZEmcDz0lJQV5eXnw+/244447UFRU5PVR8T0WVzqOg2AwiKamJlRXV+Oee+6J
c2ECwOzZs5HePR2DBw9GcXExGGPYt28ffvCDH7Tizz333IPZs2fDdV1EIhE88sgjOHz4MCzL8vg9
depU5OTk4MyZM3j33XfR3NyMpKQkCCHQWcj9xRdfxNq1a+Ncrn379sWoUaNgGAaIKAxgxY4dOwQU
ztu0aRMFg0EP32qaRqZpUnp6Oj377LNUU1PTStMqUnXMnTs3LgKzYsWKVphS4UjOOZmmSVu3bqVv
fetb3goeOnQo1dfXe7hTWapPPvlkK+ydm5tLNTU19MILL3SoJRLxe6Jmji1vvPHGRXkFYvnXlhZc
vHjxRbvKbNumxsZGKioqiotQcs7pmmuuobNnz8bVmcjj2HZicfzixYvjxq3rOvXu3ZsOHTrkPRMK
hSgSibS583DOadeuXV4fd+3a1Won5JxTcXExhcPhCxprbP9qa2upT58+rbD7rFmzYl1wO4QQ/i++
+CLqQFYYUQUbOOfo27cvZs+ejQULFqhkCE/LKsyj3uWcY9GiRVi2bBlc1wVjDA888ABmzJjRamVl
ZERzN9TKCoVCyM7O9n5vbGxEY2Oj16bP50NTUxPWrVvnaRWlDSZNmoSePXti8ODBWLhwobfC1Vgq
Kirw8ssvexpG4bU5c+bgG9/4BgKBQCvNMn36dADAZ599FhfHb48Mw0BjYyPee+897zuFQf1+P4YN
GwYhBEgSYgIx7RIRQQiBgwcPYufOnd58SCnBGMPcuXORkpICxpiXR91RHgZjDJZlwaf5UFxc7PGd
MQafz4eRI0eiX79+KmiEQCAAy7KwadOmuGipz+fDqFGjMHToUK+fq1atatWe2rlaAh8SQIdbDefc
AKAJV2D37t04c+aMNydqdywsLIzygXEXwF+qq6vDUkr41BajXBy9evXCggULMH36dPTv3z9OqAL+
gOd+Eq4A1zgYYygvL8fPfvYzz+gaOXIkHnroIRiGASklATgKoDcA7ZJLLvG2QCEEzp07h8GDB3vG
YENDA86cOYPs7GxIIcE1jp07d3qhRfVceno6ioqKPJdXQUGB56ppaRcvvvhim5OqonxqEhIn+513
3sH3v/99OI7TqfDGZjz5/f4442T69OkYOXJk3BbYGalI1vvvv49jx44BOG/k5OTkoKCgwBNcn8/X
afKQWrDlleX4+OOP46CFrusoLCyMfmbca6empgYff/yxZ+Q5jgMiwt133+3Ve+bMGSxbtqxVezk5
OcjJyVEf9wB4ENEIXFvUBcAiy7JyTNPEjh070NzcHMfbfv364dprr41CIY5TADY0NTUhKSkJPikl
OY7DAoEAfvjDH+KBBx6AaZpxOOr48eOYMGECGhsbkZyc7Fnuc+bMwTPPPINXXnkF1dXV8Pv9AID5
8+er1Uyapu1CNGPsZcaYpjQvELV0leZVQtnY2Ihz585nRhIR3nzzTTQ1NXnvKCapIyJqAlUGklps
MSmXHo0ePRpDhw6NS99MpI0bN6KmpuaChE1pJSFEnICaponbbrvNw7AX6t/UdR2hUAgrV66Mq0/X
dVx++eXo2bMnAHgatzNSmnvDhg2ora31vleCedVVV0V3zxZFBESP7hw8eNAbn6ZpyMzMRF5eHnRd
BwBUVFTg8OHDrdqbMWOG2pUFgFUAtnbQvZHCFb2U/33Tpk1xPwohMGPGDHTt2lV9tQ/AwWAwGJUD
IUQk0c/Z8lfYtu2o7773ve95VroqQ4YMoeeeey4O5951112x4d/TRDTFddyxRNQgpaTS0tI4i/WJ
J56g0tJSMk3T++6ll17y8NyhQ4do0KBBcRhW0zS68847W0XmYunAgQOUk5Pj4TBVXnrpJWpsbGwX
fx4/fpyGDh16UZ6GWP+k8n1eccUVVFtb22b0KhGfxhbXcam0tNQLZceO+dVXX73gemIx5fHjx6mg
oKBVvwsKCqiuri5u/KFQiMaOHdvq2VmzZnkR0UgkQk8//XQr70OfPn3owIEDqqp6KeXVRO1mrTEi
ephashZLSkooEAi08mxt3bpVjUMS0X/Zts2rqqpQVVUFH+f8AwDTED1DRo7jOLquHwbwW13XLyGi
HwBg9913H1auXOn59aSU+OKLL/DII494K2XgwIH48Y9/rKxCkiT/r+M4H+q6nmPbdpgxlpqamurh
KiEEzp49i9TUVASDQQ/vKK3HGMOuXbvitKDSJNOmTYNlWzh3Kj5/XUWgSkpKUF1d3Uo75efnIxQK
obm5GeFwGKZpQtM0pKamwjAMHDt2DJdeeikGDRrUqWcAiO5M69at8/CZ0rDDhw9HZmZmp+8nkitc
rF27FuFwGCpRSiXiXH311RcdoeKco7q6GhUVFQDO++qBKKxJT0+Pe/7TTz9FeXl53PtSSowfP97b
CQzDwLvvvtuqncmTJyM7O1v19ygR7etgd9ARzTpjAFBcXOxBLtVmVlYWBgwYgBaPU5hzvubkyZOS
iKI7LBFlEdFPiWgzEa2QUt7qOm5mSUkJXMcdTUQhtdqmTp3qWeqxfkNVXn/9daUNpRDiEyLq+8or
ryASiWQR0X4ioqNHj1Lv3r2992fPnk1Hjx6l3NxcbwXfeOONXhz7wQcf9KJAqp1BgwbR0aNH6fnn
n6eePXvGlb59+1J6ejoFg8E2Izp9+/aljIwM6tOnD2VnZ1N6ejrl5uZScXGxp6kuJhafmCOsytKl
S736LkbzhkIhGjlyZFzUCgAVFRVRQ0PDBXk/EnOu1e4Y63HJysqiI0eOtBrPL3/5y1Y5IMFgkPbu
3es98/HHH8dF9ZSPt7i4WO0eUgjxQktyVHuatz8R1SiZyMnJabWzP/jggyoXRhLRRiGEWVlZiYMH
D+Lzzz+Hr6SkpAbAf6nlIKVEUlJSNNLh0z4BsJGIrtV1nU2ePBmbN2+GZVlxWkHTNNx+++3qKA0c
x2nQdf3xn//850f8fj9M0wxLKc8BQGpqKtLS0nDq1Cm4rhunedUqramp8U6cbt682euXMohuuOEG
JCcn469//WscjgPOR4U0TYNhGHBdN+7348ePt/I1duvWDVdeeSWampo8LBkOh9uMWCXSRx995Fnk
ymvTpUsX5OXlQbgC4Ui4w/cTNWl5eblnqCl+JCcnY+7cuUjukhxnECaOu73Pq1at8jwTyt89ffp0
ZGVltapn3bp1njfJdV0IITB27FgMvGwgqOXUzKJFizyPijJWBw0ahHHjxsG2bZim2QzgrSeeeEL8
5Cc/aW/o1wPoads2tm3bhmPHjsX10TAMTJkyRdleLoA/HTp0yFI2jeu68F199dUd8dYhot8wxq6W
QqYUFhbiueeew8mTJz0r3DRN5Obm4vHHH4fm00BEpOv6cgCbpk+frgTABtCgcmmzsrJQUVEBXddx
+PBh6LqOXr16Yc+ePSAinDhxArZto6SkxDvXzzn3LPprr70WFRUV2LNnT5tGixJyJXyxAQvl2ov9
e/nQy5GWlobHH38cVVVVcYkoKlDTHm3ZssUTXOXtmDVrFtLT07F8xXL84Q9/6FB4E70QJ06cQF1d
ndem67ro378/pkyZguoj1bj33nsvSnillNi2bZs3JtXm6NGjW43r8OHDqKioiAtLc84xb948uMIF
uYRTp05h8+bNXvhdKYLRo0cjLS0NnHNyHOdjXdc/ue6669obdhDArQC4YRieoaYOLwghkJubiwkT
JqjnTwLYlniHxoUcfd8GYBfjbOKAAQMwZcoUvPnmm17nLctCKBRCIBDwfIUASiorK5uklEqAHACn
AUDjGvr06QMgak2Gw2HU19eje/fuXoN1dXX44osv8Nprr7XqzMCBA5Gbm4uKigqMGTOm1WkH13Wx
fft2WJYFKaW3kvv164chQ4Z45+2Ui8vn82HOrXNw6NAhLF26FGfOnIlLyG/Lh0ox8X6KieJRy10P
d999N3w+H371q1+1mbzfkfC29fv06dORlJSEd955p00PihKyROFNrFt9zsrKwuTJk73vlVAXFxej
vr4+zp2Wm5uL8ePHe379HTt2oKamppULsbCwED6fD5qmCc758t27d0eUAkjsF6KndS4Hoj7x0tJS
T+sqJVBUVITU1FT1WgWALy5AVuPJtm1ORAtUvuaOHTsoLS0tDpMpK57Iy+RaLqVM2bNnD8rKykBE
mhDit7G4SuGv1NRUKisro/nz58dZ+MuWLaOcnJxWmPX++++PyxtNpLKyMsrOzm6V/bR48eK45O2Y
ODkRRSN4wIV5F2KLyptQ0b8RI0ZQJBKhbdu2xWVcXUyJ9ZCouH4oFKIbb7zx76ov1mZgjFFhYSGF
QiFv7K7jUkNDA40bN66VB+HWW2+N80I9+uijcfOk7IgY/HxCCHHF9u3bsW3btlZnElu8DE+qhzdv
3kyGYcTlhqSlpVFpaal6RBLRgpb34nBzp6br5s2bpWVZazSfZkuSGDt2LMaPHx/nB5ZS4pVXXokG
OqJRpJGMsZ7Kqkc00nJc1Zmdne35Y5Xmjc15AKLY68iRI3EaA4AXtZPi/BYYWzZt2oSTJ08CgIfv
+vbti6KiIggpoOs6OOdxvtcTJ07ERYva2oLbKkprKR8vYwxjx46FaZooKSmB67re9UbtlcQ61XeK
MjMzMWLECNTX12P79u0XrXwSiYhw8803IxA4f/eHJImysjKUlZUBiD/Wdc0113h8sm0bq1evjpsP
KSWKiorQu1fvKC9csZ9zflDXde94UQL5AIxRH55//nlvB1dzM2zYMAwZMgTCFQAQArC6RbBbVdQh
GYYB0zT3UfTGmRuIiBUXF6OpqQmRSASGYXiunBgDRyK6KiGlRGlpKeXl5R2WUhJjjF133XU4evQo
IpGIl5gzZswYfOc73/HCwb/4xS/iAglSSowYMQLTpk1TbZwG8EHL1VGTAZinTp3CH//4R1iWFZcK
OGHCBBiGAY1r4Jyfchxnra7rAsBUAD27devGNm7ciEgkEoeFExNYEqktONGjRw9YloUHHngAd9xx
xwW/qwQkMWXQMAykpKQAiBpziQZoZ4IKRN2HajyGYXgRSCCaWOTz+bB69WoPCii4FQwGMWXKFK+e
pKQkbNq0CbFwgIiQkZEBkgTOOYHjPeGKRmXctTNmD3MkQkMV3jd0Ay0C+wGAqrbG16nwOo6DDz74
wC4oKHjG5/MVMMZSA4FA3MptWSHQfJoS5DIp5fFAIIDGxkYVYtys6/pRANmBQABJSUnRAWvcMw6U
9RsMBrFz506PkUpL3X777bFd2wrgbinldM75ZCklqqqqPAPPNE3Ytu2dzmiJ/hERvaXr+n+2MOZZ
AD/QdZ0pj8eXPSQZi5V1Xb9w4WUtwksy7jPj509kp6amdpjH0FZf2mrL26kkwe/3o6GhAVu2bIm2
26J1pZSYMGEC0tPTIYX08jIS/cKenAgHGrSTjuP8adeuXVLTtPY0r4PorUTXAWBt+cJjFnCIiH7L
2rgDDbgwgw0+nw/btm3bNX78+P90HOdOznhvrnHeBhObdV3/CMDTe/fuDbmu6wH9srKymjFjxswh
SY8wzoZxzjUFWlqu7+lORF0YY9i4cSOqqqo8Zkop0b17d0yaNMkbH4C1AEKc8yK0JKuvXLnS0x62
bUNKiR49emDq1KnqvXOMsUUvv/yyaxgG5s2b91wLDyYyxlIu9jqijgSmI8HtiDS0n7xzsQurnfaJ
MWYDSGMa6w1Ek5A+++wzzwhXC++ee+6J3ioUhWh7KHp1lh8xp7eFK0hIIQzD+My27d9UVlYeojYS
mhL68gcAyQBmAshAwmlwzrkAcArAmwA+bG98nQqvWoUtuQV/0HX9HQBx565jsqWkEOJcRUWF1dTU
hEAgANd1vcSO3bt3b7niiit2IZqQEQsL/ACKpZBjLNvCyy+/HLctMcYwbNgwL64PoB7AegBZlmVd
Y5omO3PmDLZt2xbX70AggCuvvBI9evSAcAVJkhWc8b1JSUnKX1k7b968+QCCUkpNMTkWqnQgBG1+
rwRM8e1i3r2QuVAa80JIaXF1m5GCUQD8jLHXAfQmIqxZswaNjY0e5pRS4pJLLvGSgDSfJoloAWPs
f4B4O0nzadCgkW3b4U8//TSkbCDTNL18lDYoBOC/AfwfIjLa4YcNoBFRRfWPSSUlJSCiMURUT0RU
UVFBGRkZcdExwzBowYIFKvIliehPROQjogdaPtPOnTupR48ecVa2ruv09ttvKw+IEEL83LZtuI7r
lX91aucmokuJ6AhRNId2yJAhrW45uvPOO2M9OhVE1LWtSNnXSV/NLRhfpgPRlT4HQNC2bWzdutVL
i1PM5pyjsLBQeTgsAH9G9KrMwkgkwgDg/fff97LRlMU+cOBATJkyReUg25zzDxWW/DenPCLKJCKU
lZWhoqIiLiBDRJg6darShhLAnxDd7f6h6Cu5XPrL0Pjx41MBTCcirus6Dhw4gB49eiAjI8OzhPv3
748RI0aoV44C2AkgE8BAlW+6e/duZGVleQcD/X4/CgsLkZaWpm6FOQigTG2l/+Y0FdHEGFRWVnpJ
SCo/uGvXrhg3bpx6tg7A8q+7w23R134NIhGlASgD0M+yLDQ0NHinMRRmNE0z1sotBnA7gGwiWssY
6y+lRF1dnTcB6jZKn8+Hrmldofk0IqInGGPPOI4Tt9f9q99+3s5p3T8CuAUAU352IQSULSClRK+e
vZQt8y6AG9HOiYivwMb95yXLshgRPUNEkU6yuSQRNRHRTQcPHoRt211s2/49tXMFVMJ7B13HzbEs
y7trS5V/dWoH886UUjZcwO08DhHd1VZ06x8B837ttH37djQ2NqYQ0U8ty6onIruNYhFRFRHdQkRG
ZWUl9u/fDyFEBhE9T0S1FE3dbI4pISI6R0TbiWiS67j4/8LrFVNKeYeUcp+U8pyUsjGhnJNSVkkp
7yOiLu0J7tctvP8PWh/DSyOm/pIAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjQtMTItMDNUMDc6MTE6
MTErMDA6MDBRxMU7AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDI0LTEyLTAzVDA3OjExOjExKzAwOjAw
IJl9hwAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyNC0xMi0wM1QwNzoxMToxMSswMDowMHeMXFgA
AAAASUVORK5CYII=" />
    </svg>
);

export default BrandIcon;