import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PagePathConstants from '../constants/router/PagePathConstants';
import BrandIcon from '../icons/BrandIcon';
import debounce from 'lodash/debounce';

import Video1 from '../images/matsukiyococokara/1.mov';
import Video2 from '../images/matsukiyococokara/2.mov';

import tracker from '../tracker';
import * as FBUtil from '../utils/FbUtil';
import { isMobileScreen } from '../utils/ResponsivePageUtils';
import { generateRandomString } from '../utils/RandomGeneratorUtils';
import * as fbEventActionCreator from '../actions/fbEventActionCreator';
import { sendCustomEvent } from '../apis/ConfigAPI';

const teslaAdElement = [Video1, Video2];
const teslaAdElementMobile = [Video1, Video2];

class TeslaPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            adOffsetTop: [],
            scrollTop: 0,
            activeAdIndex: 0,
        }

        this.routeToOrderPage = this.routeToOrderPage.bind(this);
        this.displayVideo = this.displayVideo.bind(this);
        this.calcTeslaAdOffsetTop = this.calcTeslaAdOffsetTop.bind(this);
        this.handleScroll = debounce(this.handleScroll.bind(this), 1000);
        this.goBack = this.goBack.bind(this);
    }
    videoRef = React.createRef();
    sliderContainerRef = React.createRef();

    goBack() {
        const { history } = this.props;
        history && history.goBack();
    }

    componentDidMount() {
        this.displayVideo();
        setTimeout(() => {
            this.calcTeslaAdOffsetTop();
        }, 1000);
        sendCustomEvent('RouteToTheVideoPage');
    }

    calcTeslaAdOffsetTop() {
        const teslaAdComponents = document.querySelectorAll('.tesla-advertising-map');
        let offsetArr = [];
        for (let i = 0; i < teslaAdComponents.length; i++) {
            offsetArr.push(teslaAdComponents[i].offsetTop);
        }
        this.setState({ adOffsetTop: offsetArr });

    }

    handleScroll() {
        const sliderScrollTop = this.sliderContainerRef.current && this.sliderContainerRef.current.scrollTop;
        if (this.state.adOffsetTop.indexOf(sliderScrollTop) >= 0) {
            this.setState({ scrollTop: sliderScrollTop });
            return;
        }
        const { scrollTop, activeAdIndex, adOffsetTop } = this.state;
        if (sliderScrollTop !== undefined && sliderScrollTop !== null) {
            let newIndex;
            if (scrollTop <= sliderScrollTop) {
                // 向下滚动
                newIndex = (activeAdIndex + 1 > (adOffsetTop.length - 1)) ? activeAdIndex : activeAdIndex + 1
            } else {
                // 向上滚动
                newIndex = (activeAdIndex - 1 < 0) ? activeAdIndex : activeAdIndex - 1
            }

            this.setState({ scrollTop: sliderScrollTop, activeAdIndex: newIndex }, () => {
                const teslaAdComponents = document.querySelectorAll('.tesla-advertising-map');
                teslaAdComponents[newIndex].scrollIntoView({ behavior: "smooth", block: "start" });
                this.setState({ scrollTop: adOffsetTop[newIndex] });
            });
        }
    }

    async displayVideo() {
        this.videoRef.current.style.width = document.body.clientWidth + 'px';
        this.videoRef.current.style.height = this.sliderContainerRef.current.clientHeight + 'px';
    }

    routeToOrderPage() {
        const { history, location, fbEventActions } = this.props;

        const eventId = generateRandomString(10);
        const eventParams = {
            content_ids: 'matsukiyococokara-coupon',
            content_category: 'Coupon',
            content_name: 'Coupon',
            content_type: 'product',
            contents: [{
                id: 'matsukiyococokara-coupon-10000',
                title: 'Matsukiyococokara Coupon',
                quantity: 1,
            }],
            currency: 'JPY',
            value: 330
        };
        FBUtil.trackFBEvent('ViewContent', eventParams, eventId);
        sendCustomEvent('ViewContent');
        fbEventActions && fbEventActions.submitFBConversionEvent('ViewContent', eventParams, null, eventId, null);
        tracker.trackTeslaHomePage();
        history && history.push({ pathname: PagePathConstants.VEHICLE_MODEL, search: location.search });
    }

    render() {
        let headerTitle = `本日残りのクーポンは${Math.floor(Math.random() * (200 - 20 + 1)) + 20}枚です`;
        return (
            <div className='tw-w-full tw-h-full tw-bg-black notoSerif-font'>

                <div className='tw-fixed tw-top-0 tw-left-0 tw-z-[100] tw-w-full tw-h-[53px] phone:tw-h-[92px] tw-flex tw-justify-between tw-items-center tw-text-white hs-tesla-banner tw-flex-wrap'>
                    <div className='tw-w-[5%] phone:tw-w-[10%] tw-pl-[20px]'></div>
                    <div className='tw-w-[90%] phone:tw-w-[90%] tw-h-full tw-flex phone:tw-flex-col tw-justify-center tw-items-center'>
                        <div className='tw-text-[17px] phone:tw-text-[16px] tw-font-bold'> {headerTitle} </div>
                    </div>
                    <div className='tw-w-[5%] phone:tw-w-0'></div>
                </div>
                <div className='tw-w-full tw-h-full phone:tw-h-full'>
                    <div className='tw-z-[100] tw-w-full tw-h-[120px] tw-fixed tw-top-[53px] phone:tw-top-[92px] tw-left-0 tw-flex tw-justify-center tw-left-0 '>
                        <BrandIcon size={50} />
                    </div>
                    <div className='tw-z-[100] tw-w-full tw-h-[120px] phone:tw-h-auto tw-fixed tw-top-[220px] phone:tw-top-[300px] tw-left-0 tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-white'>

                        <div className='tw-text-[30px] tw-font-[800] tw-px-[50px] tw-mt-[30px] tw-text-center'>閾値なしクーポンは2種類から選択できる</div>
                    </div>
                    <div className='tw-w-full tw-h-full tw-overflow-scroll tesla-swiper-container tw-relative'
                        ref={this.sliderContainerRef} onScroll={this.handleScroll}>
                        {(isMobileScreen() ? teslaAdElementMobile : teslaAdElement).map((url, index) => {
                            if (index !== 2) {
                                return <div className='tw-w-full tw-h-full tw-relative tesla-advertising-map' key={url}>
                                    <video className='tw-object-cover tw-w-full tw-h-full' ref={this.videoRef} autoPlay webkit-playsinline="true" playsinline="true" src={url} muted="muted" loop />
                                </div>
                            } else {
                                return <div className='tw-w-full tw-h-full tesla-advertising-map' key={url}>
                                    <div style={{ backgroundImage: `url(${url})`, backgroundPosition: 'center', backgroundSize: 'cover' }} alt='tesla-img' className={`tw-w-full tw-h-full`} />
                                </div>
                            }
                        })}
                    </div>
                    <div className='tw-w-full tw-h-[120px] tw-fixed tw-bottom-0 tw-left-0 tw-bg-gradient-to-t tw-from-black tw-to-[rgba(255, 255, 255, 0)] tw-flex tw-flex-col tw-justify-center tw-items-center '>
                        <button className='tw-px-[20px] tw-h-[40px] tw-bg-brand-secondary tw-text-white tw-rounded-[10px] tw-font-[600] tw-opacity-80' onClick={this.routeToOrderPage}>クリックしてクーポンを受け取る »</button>
                    </div>
                </div>

            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fbEventActions: bindActionCreators(fbEventActionCreator, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(TeslaPage);
