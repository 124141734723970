import React, { Component } from 'react';
import LogoFooter from '../../../images/matsukiyococokara/logo_footer.png';

class Footer extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const {classProps} = this.props;
        return (
            <div className={"tw-w-[660px] phone:tw-w-full tw-bg-black tw-text-white tw-mx-auto tw-pt-[22px] tw-pb-[22px] " + classProps} >
                <img src={LogoFooter} alt="logo" className='tw-w-[320px] tw-mx-auto' />
                <div className='tw-mt-[22px] tw-text-[12px] tw-text-center'>Copyright Matsumotokiyoshi Group Co., Ltd. All Rights Reserved.</div>
            </div>
        );
    }
}

export default Footer;